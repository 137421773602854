<template>
<div style="width: 100vw; height: 100vh; overflow: hidden;">
  <div style="background-color: transparent; z-index: 99999; color: white; position: absolute; padding: 100px;">
    <h1 style="font-size: 18px; font-weight: bold;">Signing in from Digital Gateway</h1>
    <h2 style="font-size: 18px; font-weight: bold;">{{ message }}</h2>
    <b-spinner label="Contacting server..." variant="primary" style="margin-top: 20px;"></b-spinner>
  </div>
  <div class="digitalgateway">
  </div>
</div>
</template>

<script>
// delay function
function delay (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export default {
  name: 'DigitalGateway',
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.referrer = document.referrer
    await delay(4000)
    this.login()
  },
  data () {
    return {
      message: 'contacting server...',
      referrer: null
    }
  },
  methods: {
    login: async function () {
      try {
        const init = {
          headers: {
            'X-API-KEY': this.$route.params.id
          }
        }
        window.localStorage.setItem('frmCloudExApiKey', this.$route.params.id)
        let response = await this.$Amplify.API.get('cosmos', '/account', init)
        if (response.key === this.$route.params.id) {
          this.message = 'Logged in'
          const account = response
          if (account.acgroups) {
            account.acgroups = ['norole']
          }
          window.localStorage.setItem('frmCloudExAcgroups', JSON.stringify(account.acgroups))
          window.localStorage.setItem('frmCloudExApiKey', response.key)
          window.localStorage.setItem('frmCloudExUsername', account.organisation)
          const user = { acgroups: account.acgroups }
          this.$store.commit('setKey', response.key)
          this.$store.commit('setUsername', account.organisation)
          this.$store.commit('setUser', user)
          this.$stat.log({ page: 'digitalgateway', action: 'logged in' })
          if (account.homepage) {
            this.$router.replace({ name: account.homepage })
          } else {
            this.$router.replace({ name: 'Start' })
          }
        } else {
          this.message = 'Invalid credentials'
          this.signOutExternal()
        }
      } catch (e) {
        this.message = 'Invalid credentials'
        this.$logger.warn('getCategories loading error' + e)
        this.signOutExternal()
      }
    },
    signOutExternal: function () {
      window.localStorage.removeItem('frmCloudExApiKey')
      window.localStorage.removeItem('frmCloudExUsername')
      this.key = null
      this.username = null
      this.user = null
      this.account = false
      this.$store.commit('setKey', null)
      this.$store.commit('setUsername', null)
      this.$router.push({ name: 'Settings' })
    }
  }
}
</script>
<style scoped lang="scss">
.digitalgateway {
  position: relative;
  z-index: 900;
  height: 300vh;
  width: 400vw;
  margin-left: -100vh;
  background-color: rgb(0, 14, 39);
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='100px' height='100px' viewBox='0 0 100 100' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Crect fill='rgba(0,70,194,.4)' x='5' y='5' width='90' height='90' rx='8'%3E%3C/rect%3E%3C/svg%3E");
  transform: rotate(45deg);
}
</style>
